<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="deployments"
      sort-by="name"
      class="elevation-1"
      dense
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Deployments</v-toolbar-title>
          <v-divider class="mx-4" inset vertical />
          <v-spacer />
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Hinzufügen
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <p v-html="formDescription" class="subtitle-2 text-left" />
                <v-divider class="mx-1" />
                <v-container>
                  <v-form ref="form_deploymentliste" v-model="valid">
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          :value="editedItem.name"
                          v-model="editedItem.name"
                          label="Name"
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          :value="editedItem.stage"
                          v-model="editedItem.stage"
                          label="Stage"
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          :value="editedItem.url"
                          v-model="editedItem.url"
                          label="URL"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text @click="close">
                  Abbruch
                </v-btn>
                <v-btn color="primary" text @click="save">
                  Übernehmen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Möchten Sie das Deployment entfernen?</v-card-title
              >
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" text @click="closeDelete"
                  >Abbruch</v-btn
                >
                <v-btn color="primary" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:[`item.created`]="{ item }">
        <span>{{ formatDate(item.created) }}</span>
      </template>
      <template v-slot:[`item.updated`]="{ item }">
        <span>{{ formatDate(item.updated) }}</span>
      </template>
      <template v-slot:[`item.url`]="{ item }">
        <a :href="item.url" target="_blank">
        {{ item.url }}
        </a>
      </template>
    </v-data-table>
    <v-container>
      <v-row>
        <v-spacer />
        <v-col cols="12" sm="6" md="6" class="text-right">
          <v-btn @click="initialize" outlined> Neu laden </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar" :timeout="snackbar_timeout" color="darklagoon">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="amarillo" text v-bind="attrs" @click="snackbar = false">
          Schliessen
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import DeploymentDataService from "../services/DeploymentDataService.js";
export default {
  data: () => ({
    valid: true,
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "ID", value: "id" },
      { text: "Name", value: "name" },
      { text: "Stage", value: "stage" },
      { text: "URL", value: "url" },
      { text: "Erstellt", value: "created", dataType: "Date" },
      { text: "letztes Update", value: "updated", dataType: "Date" },
      { text: "Aktionen", value: "actions", sortable: false },
    ],
    deploymemnt: [],
    deployments: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      stage: "",
      url: "",
      id: "",
    },
    defaultItem: {
      name: "",
      stage: "",
      url: "",
      id: "",
    },
    isEditModus: false,
    snackbar: false,
    message: "",
    snackbar_timeout: 8000,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Deployment hinzufügen"
        : "Deployment ändern";
    },
    formDescription() {
      return "Bitte hier die entsprechenden Daten zu einem Deploymemnt erfassen.</br>Berücksichtigen Sie dabei alle Stages in den es ein Deployment gibt.";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loadDeployments(true);
    },
    async loadDeployments(ismessage) {
      let resp = await DeploymentDataService.getAll();
      if (resp.status == 200) {
        this.deployments = resp.data;
        if (ismessage == true) {
          this.message = "Deployments gelesen.";
          this.snackbar = true;
        }
      } else {
        this.message = resp.data;
        if (!this.message) {
          this.message = "Keine Daten vorhanden";
        }
        this.snackbar = true;
      }
    },
    editItem(item) {
      this.editedIndex = this.deployments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.isEditModus = true;
    },
    deleteItem(item) {
      this.editedIndex = this.deployments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.deployments.splice(this.editedIndex, 1);
      this.closeDelete();
      this.deleteService();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.isEditModus = false;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          this.updateService();
        } else {
          this.addService();
        }
        this.close();
      }
    },
    getDeployment(value) {
      this.loadDeployment(value);
    },
    async loadDeployment(value) {
      let resp = await DeploymentDataService.getById(value);
      this.deployment = resp.data;
      if (resp.status == 200) {
        this.message = "Deployment gelesen.";
        this.snackbar = true;
      } else {
        this.message = resp.data;
        this.snackbar = true;
      }
    },
    async addService() {
      try {
        let resp = await DeploymentDataService.create(this.editedItem);
        if (resp.status == 201) {
          this.message = "Änderungen wurden gespeichert.";
          this.loadDeployments(false);
        } else {
          this.message = resp.errorMessage;
        }
      } catch (err) {
        this.message = err;
        this.initialize();
      }
      this.snackbar = true;
    },
    async updateService() {
      try {
        let resp = await DeploymentDataService.update(
          this.editedItem.id,
          this.editedItem
        );
        if (resp.status == 200) {
          this.message = "Änderungen wurden gespeichert.";
          this.loadDeployments(false);
        } else {
          this.message = resp.errorMessage;
        }
      } catch (err) {
        this.message = err;
        this.initialize();
      }
      this.snackbar = true;
    },
    async deleteService() {
      try {
        let resp = await DeploymentDataService.delete(this.editedItem.id);
        if (resp.status == 204) {
          console.log("Erfolgreich gelöscht");
          this.message = "Änderungen wurden gespeichert.";
          this.loadDeployments(false);
        } else {
          this.message = resp.errorMessage;
        }
      } catch (err) {
        this.message = err;
        this.initialize();
      }
      this.snackbar = true;
    },
    validate() {
      this.$refs.form_deploymentliste.validate();
    },
    formatDate(item) {
      if (item === null) {
        return "--- ";
      }
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZoneName: "short",
      };
      return new Date(item).toLocaleString("de-DE", options);
    },
  },
};
</script>
