//import { createStore } from 'vuex';
import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

function getDefaultState() {
  return {
    token: "",
    user: {},
    username: "",
    roles: {},
  };
}

export default new Vuex.Store({
  strict: true,
  modules: {
  },

  plugins: [createPersistedState()],

  state: getDefaultState(),

  getters: {
    isLoggedIn: state => {
      return state.token;
    },
    getUser: state => {
      return state.user;
    },
    getUserName: state => {
      return state.username;
    },
    getRoles: state => {
      return state.roles;
    },
    getToken: state => {
      return state.token;
    }
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_USER_NAME: (state, username) => {
      state.username = username;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    login: ({ commit }, { token, user, username, roles }) => {
      commit("SET_TOKEN", token);
      commit("SET_USER", user);
      commit("SET_USER_NAME", username);
      commit("SET_ROLES", roles);
      // set auth header
      Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    },
    refreshToken: ({ commit }, { token }) => {
      commit("SET_TOKEN", token);
      // set auth header
      Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    },
    logout: ({ commit }) => {
      commit("RESET", "");
    }
  }
});
